import debounce from 'debounce'

const carousel = () => {
  const list = document.getElementById('carousel-items')
  const items = Array.from(list.getElementsByTagName('li'))
  const lightbox = document.getElementById('lightbox')
  const lightboxItem = document.getElementById('lightbox-item')

  let currentIndex = 0
  let transitioning = false
  let lightboxVisible = false

  const next = () => {
    if (!transitioning) {
      currentIndex >= items.length - 1 ? currentIndex = 0 : currentIndex++
      translateList('next')
      if (lightboxVisible) populateLightbox()
    }
  }

  const previous = () => {
    if (!transitioning) {
      currentIndex === 0 ? currentIndex = items.length - 1 : currentIndex--
      translateList('previous') 
      if (lightboxVisible) populateLightbox()
    }
  }

  const toggleLightBox = () => { 
    if (!lightboxVisible) populateLightbox()

    pauseVideos()
    lightboxVisible = !lightboxVisible
    lightboxVisible ? lightbox.classList.add('active') : lightbox.classList.remove('active')
  }

  const pauseVideos = () => {
    const iframes = Array.from(document.getElementsByTagName('iframe'))
    const videos = Array.from(document.getElementsByTagName('video'))

    videos.forEach(video => video.pause())
    iframes.forEach(iframe => {
      iframe.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*')
    })
  }

  const resizeEmbeds = (iframes) => {      
    iframes.forEach(iframe => {
      const ratioHeight = iframe.parentNode.offsetWidth * 0.5625
      const ratioWidth = iframe.parentNode.offsetHeight * 1.7777777

      iframe.style.height = `${Math.min(ratioHeight, iframe.parentNode.offsetHeight)}px`
      iframe.style.width = `${Math.min(ratioWidth, iframe.parentNode.offsetWidth)}px`
    })
  }

  const populateLightbox = () => {
    lightboxItem.innerHTML = items[currentIndex].innerHTML
    Array.from(lightboxItem.querySelectorAll('img')).forEach(img => img.sizes = '100vw')    
    resizeEmbeds(Array.from(lightboxItem.getElementsByTagName('iframe')))
  }

  const translateList = (direction) => {
    if (items.length === 1) return // HACK: return if only one item, to prevent blank next.
    
    transitioning = true
    let oldItem

    if (direction === 'next') {
      oldItem = items[currentIndex === 0 ? items.length - 1 : currentIndex - 1]
    } else if (direction === 'previous') {
      oldItem = items[currentIndex === items.length - 1 ? 0 : currentIndex + 1]
    }

    pauseVideos()

    oldItem.classList.remove('active')
    oldItem.ontransitionend = () => {
      list.style.transform = `translate3d(-${(currentIndex) * 100}%, 0px, 0px)`
      items[currentIndex].classList.add('active')
      history.replaceState(null, null, items[currentIndex].id)
    }
    items[currentIndex].ontransitionend = () => {
      transitioning = false
    }
  }

  const getById = (targetId) => {
    currentIndex = items.findIndex(({ id }) => id === targetId)
    list.style.transform = `translate3d(-${(currentIndex) * 100}%, 0px, 0px)`
  }

  const initialLoad = () => {
    list.style.transform = `translate3d(-${(currentIndex) * 100}%, 0px, 0px)`

    if (location.pathname.substr(-1) !== '/') {
      history.replaceState(null, null, location.pathname + '/' + items[currentIndex].id)
    } else {
      history.replaceState(null, null, items[currentIndex].id)
    }
  }

  // Initialize

  document.querySelectorAll('.next-button').forEach(button => button.addEventListener('click', () => next()))
  document.querySelectorAll('.prev-button').forEach(button => button.addEventListener('click', () => previous()))
  document.querySelector('.expand-lightbox-button').addEventListener('click', () => toggleLightBox())
  document.querySelector('.close-lightbox-button').addEventListener('click', () => toggleLightBox())

  window.addEventListener('keydown', (e) => {
    switch (e.key) {
      case 'ArrowRight':
        next()
        break
      case 'ArrowLeft':
        previous()
        break
      case 'f':
      case 'F':
        toggleLightBox()
        break
      case 'Escape':
        (lightboxVisible) && toggleLightBox()
        break
      default:
    }
  }, true)

  const id = list.dataset.initialId
  parseInt(id) ? getById(id) : initialLoad()
  
  items[currentIndex].classList.add('active')
  lightboxItem.innerHTML = items[currentIndex].innerHTML

  resizeEmbeds(Array.from(document.getElementsByTagName('iframe')))

  window.onresize = debounce(() => resizeEmbeds(Array.from(document.getElementsByTagName('iframe'))), 100)
}

export default carousel
