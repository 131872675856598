import debounce from 'debounce'

const carouselProjects = () => {
  const list = document.getElementById('carousel-items')
  const items = Array.from(list.getElementsByTagName('li'))
  const transitionDuration = 300
  let currentIndex = 0
  let transitioning = false

  const next = () => {
    if (!transitioning) {
      if (currentIndex >= items.length - 1) {     
        currentIndex++
        translateList({callback: () => {
          currentIndex = 0 
          translateList({transition: false})
        }})
      } else {
        currentIndex++
        translateList()
      }
    }
  }

  const previous = () => {
    if (!transitioning) {
      if (currentIndex === 0) {
        translateList({value: 33.33333, callback: () => {
          currentIndex = items.length - 1
          translateList({transition: false})
        }})
      } else {
        currentIndex--
        translateList()
      }
    }
  }

  const translateList = ({value = -Math.abs((currentIndex) * 33.33333), transition = true, callback = null} = {} ) => {
    if (items.length === 1) return // HACK: return if only one item, to prevent blank next.

    transitioning = true
    list.style.transition = (transition ? `all ${transitionDuration}ms ease-in-out` : 'none')
    list.style.transform = `translate3d(calc((${value}% - ${(currentIndex) * 20}px) - (100% + 60px)), 0px, 0px)` 
    // subtract (100% + 60px) to make first project at far left

    const cleanUp = () => {
      transitioning = false
      if (callback) callback()
    }
    
    transition ? list.ontransitionend = () => cleanUp() : cleanUp() 
  }

  const createClones = () => {
    const startItems = items.slice(0, 3)
    const endItems = items.slice(items.length - 3, items.length + 1).reverse()

    endItems.forEach(li => {
      const clone = li.cloneNode(true)
      clone.id = clone.id + '-cloned'
      list.prepend(clone) 
    })
    
    startItems.forEach(li => {
      const clone = li.cloneNode(true)
      clone.id = clone.id + '-cloned'
      list.appendChild(clone) 
    })
  }

  // const animateList = () => {
  //   let value = 0
    
  //   setInterval(() => {
  //     value--
  //     list.style.transform = `translate3d(calc((${value / 100}% - ${(currentIndex) * 20}px) - (100% + 60px)), 0px, 0px)` 
  //   }, .1);
  // }

  const sizeTitles = () => {
    const titles = Array.from(list.querySelectorAll('.project-title a'))
    const titleContainers = Array.from(list.getElementsByClassName('project-title'))
    let height = 0

    titles.forEach(title => { if (title.offsetHeight > height) height = title.offsetHeight })
   
    titleContainers.forEach(titleContainer => titleContainer.style.minHeight = `${height}px`)
  }

  // Initialize
  createClones()
  sizeTitles()
  translateList({transition: false})

  document.querySelector('.next-button').addEventListener('click', () => next())
  document.querySelector('.prev-button').addEventListener('click', () => previous())

  window.addEventListener('keydown', (e) => {
    switch (e.key) {
      case 'ArrowRight':
        next()
        break
      case 'ArrowLeft':
        previous()
        break
      default:
    }
  }, true)

  window.onresize = debounce(sizeTitles, 50)
}

export default carouselProjects