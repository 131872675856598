import 'frontend/stylesheets'
import 'frontend/svg'
import 'shared/images/favicon.png'
import debounce from 'debounce'

import lazySizes from 'lazysizes'
import carousel from 'frontend/javascripts/carousel.js'
import carouselProjects from 'frontend/javascripts/carouselProjects.js'
// import verticalCenterPadding from 'frontend/javascripts/verticalCenterPadding.js'

lazySizes.cfg.expand = 1000
lazySizes.cfg.expFactor = 4
lazySizes.cfg.hFac = 3

const initCarouselProject = () => {
  if (document.getElementById('carousel-projects') && window.innerWidth > 768) carouselProjects()
}

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('carousel')) carousel()
  initCarouselProject()  

  window.onresize = debounce(initCarouselProject, 100)
})
